import bluestar from '../../Assests/Bluestar.jpg'
import dakin from '../../Assests/dakin.jpg'
import installAC from '../../Assests/installAC.jpeg'
import { IoCallSharp, IoMail ,IoLocationSharp} from "react-icons/io5";
import Journey from '../../Assests/journey.jpg'
import doubleskinAC from '../../Assests/DoubleSkinAC.jpeg'
import vrf from '../../Assests/vrf.png'
import splitac from '../../Assests/splitAC.png'
import cassette from '../../Assests/CassetteAC.jpeg'
import ductable from '../../Assests/Ductable.jpeg'
import airwasher from '../../Assests/AirWasherUnits.jpeg'
import finnedtube from '../../Assests/FinnedTubeCoils.jpeg'
import windowAC from '../../Assests/WindowAc.jpeg'
import FRP from '../../Assests/FRP.jpeg'
import HPE from '../../Assests/HighPressureEF.jpeg'
import industrialCooler from '../../Assests/industrial-air-cooler.jpg'
import centrifugalfan from '../../Assests/Centrifugal.jpeg'
import FCU from '../../Assests/FCU.jpeg'
import chilledCoil from '../../Assests/chilledWatercoil.jpeg'
import Cassette from '../../Assests/CassetteAC.jpg'
import Hiwall from '../../Assests/HiWallAC.jpg'
import Collage from '../../Assests/collage.jpg'
import SomLalit from '../../Assests/SomLalit.jpg'
import Talod from '../../Assests/Talod.jpg'
import Windson from '../../Assests/Windson.jpg'
import GLS from '../../Assests/GLS.jpg'
import Palanpur from '../../Assests/Palanpur.jpg'

export const Menus = [
    {
        label: "Services",
        redirect: "/services"
    },
    {
        label: "Our Products",
        redirect: "/product"
    },
    {
        label: "About Us",
        redirect: "/about-us"
    },
    {
        label: "Contact Us",
        redirect: "/contact-us"
    },
]
export const Countlist =[
    {
        label:"Residential Number of Projects",
        count:"1000"
    },
    {
        label:"Industrial Number of Projects",
        count:"25"
    },
    {
        label:"No.of Employees",
        count:"10"
    },
    {
        label:"Year of experience",
        count:"10"
    },
]
export const WorkCarosuel = [
    {
        work:Collage,
        place:"Gift City, Gandhinagar – Gift Tower – 1 , 16th Floor"
    },
    {
        work:SomLalit,
        place:"Som Lalit School - Ahmedabad"
    },
    {
        work:Talod,
        place:"Talod Food Products PVT. LTD - Talod"
    },
    {
        work:Windson,
        place:"Windson Organics PVT. LTD. - Gandhinagar"
    },
    {
        work:GLS,
        place:"GLS (GLS Law Society) University - Ahmedabad"
    },
    {
        work:Palanpur,
        place:"Ihsedu Agrochem PVT. LTD - Palanpur"
    },
]
export const Companydetail = [
    {
        number:"1000+",
        detail:"Residential Number of Projects"
    },
    {
        number:"25+",
        detail:"Industrial Number of Projects"
    },
    {
        number:"10",
        detail:"No.of Employees"
    },
    {
        number:"10+",
        detail:"Year of experience"
    }
]

export const Products = [
    {
        img: windowAC,
        title: "window air conditioner",
    },
    {
        img: ductable,
        title: "Ductable air conditioner",
    },
    {
        img: Cassette,
        title: "Cassette Air conditioning Units",
    },
    {
        img: Hiwall,
        title: "Hi-wall Split Air conditioning Units",
    }
]

export const DomesticProducts=[
    {
        img: vrf,
        title: "VRF / VRV System",
    },
    {
        img: splitac,
        title: "Split Air Conditioner",
    },
    {
        img: cassette,
        title: "Cassette Air Conditioner",
    },
    {
        img:windowAC ,
        title: "Window Air Conditioner",
    },
    {
        img: ductable,
        title: "Ductable Air Conditioner",
    }
]

export const IndustrialProducts=[
    {
        img: doubleskinAC,
        title: "Double & SIngle Skin Air Handling Unit (AHU)",
    },
    {
        img: airwasher,
        title: "Air WasherUnits / Plants",
    },
    {
        img: finnedtube,
        title: "Finned Tube Heating & Cooling Coils",
    },
    {
        img: FCU,
        title: "Fan Coil Units",
    },
    {
        img: chilledCoil    ,
        title: "Chilled Water Cooling Coils",
    },
    {
        img: centrifugalfan,
        title: "Centrifugal Fans",
    },
    {
        img: FRP,
        title: "Axial Fans with Aluminum / FRP Blades",
    },
    {
        img: HPE,
        title: "High Pressure Industrial exhaust fan",
    },
    {
        img: industrialCooler,
        title: "Industrial Cooler",
    }
]

export const ServiceBrief = [
    {
       title:"Tailored HVAC Design and Installation",
       description:"Our expert team works closely with you to design and install HVAC systems that meet your specific needs, ensuring efficiency and comfort."
    },
    {
        title:"Customized Annual Maintenance Contracts (AMC)",
        description:"Secure the longevity and efficiency of your HVAC systems with our comprehensive AMCs, designed to fit your schedule and budget."
     },
     {
        title:"Repairing Services",
        description:"Our skilled technicians are ready to provide prompt and reliable repair services for all types of air conditioning systems, minimizing downtime and ensuring continuous comfort."
     },
     {
        title:"Turn-Key Projects",
        description:"We specialize in handling turn-key projects for ventilation, AHU, air-conditioning, and exhaust work, delivering end-to-end solutions from planning to execution."
     }
     
]
export const LeadingBrands = [
    bluestar, dakin,
    bluestar, dakin,
    bluestar, dakin,
    bluestar, dakin,
    bluestar, dakin,
    bluestar, dakin,
    bluestar, dakin,
    bluestar, dakin,
    bluestar, dakin,
    // ,hitachi,Mitsubishi,Ogeneral,Toshiba,Voltas
]
export const TESTIMONIALS = [
    {
        name: "Alex M.",
        description: "ADP Airtech transformed our office environment. The custom HVAC solution they provided not only improved our air quality but also significantly reduced our energy bills. Their team was professional, efficient, and attentive to our needs throughout the project"
    },
    {
        name:"Samantha J.",
        description: "Choosing ADP Airtech for our home air conditioning was the best decision we made this year. The installation process was seamless, and the system they recommended has been perfect for our family. We've never been more comfortable!"
    },

    {
        name: "Alex M.",
        description: "ADP Airtech transformed our office environment. The custom HVAC solution they provided not only improved our air quality but also significantly reduced our energy bills. Their team was professional, efficient, and attentive to our needs throughout the project"
    },
    {
        name:"Samantha J.",
        description: "Choosing ADP Airtech for our home air conditioning was the best decision we made this year. The installation process was seamless, and the system they recommended has been perfect for our family. We've never been more comfortable!"
    },
]

export const Contacts = [
    {
        icon: <IoLocationSharp />,
        text: "C-10, First Floor, Rajshree Enclave, Shreyas Tekara Main Road, Ambawadi, Ahmedabad-380006, Gujarat, India"
    },
    {
        icon: <IoCallSharp />,
        text: "+91 99090 25682"
    },
    {
        icon: <IoMail />,
        text: "adpairtech@gmail.com"
    },
]



export const ServiceList = [
    {
        img: installAC,
        title: "Annual Maintenance Contracts (AMC)",
        description: "Our Annual Maintenance Contracts offer peace of mind and ensure your HVAC systems operate at peak efficiency year- round.Tailored to the specific needs of your setup, our AMC plans include regular check - ups, cleaning, and preventative maintenance.These services not only extend the life of your equipment but also prevent unexpected breakdowns, saving you time and money in the long run.Let us help you maintain an optimal environment with our comprehensive AMC offerings."
    },
    {
        img: installAC,
        title: "Industrial HVAC Solutions",
        description: "ADP Airtech excels in delivering industrial HVAC solutions designed to meet the rigorous demands of the industrial sector. Our team of professionals specializes in the design,installation, and maintenance of large-scale HVAC systems. We understand the complexities of industrial environments and offer customized solutions that ensure efficient temperature control, air quality, and energy management. From manufacturing facilities to warehouses, we provide robust systems that stand the test of time."
    },
    {
        img: installAC,
        title: "Residential HVAC Services",
        description: "Your home is your sanctuary, and maintaining a comfortable climate is key to your quality of life.Our residential HVAC services encompass everything from system design and installation to maintenance and repair. We offer energy-efficient solutions that not only keep your home comfortable year-round but also help reduce your energy bills. Whether you're upgrading yoursystem or need urgent repairs, ADP Airtech is here to ensure your home remains a haven of comfort."
    },
    {
        img: installAC,
        title: "Repairing of AC Systems",
        description: "When your air conditioning system encounters issues, you need fast, reliable repair services.ADP Airtech's repair services are second to none, with our team of experienced technicians ready to diagnose and fix any problem swiftly. We work with all types of AC systems, ensuring that regardless of your setup, you receive expert service to get your system back up and running smoothly. Trust us for your repair needs and experience minimal downtime with maximum efficiency."
    },
    
]

export const AboutUs=[
    { 
        img: Journey,
        title:"Our Journey",
        description:"ADP Airtech's journey began with a simple yet powerful idea: to offer unparalleled HVAC solutions that are not just efficient and reliable but also tailored to meet the unique needs of every customer. From the bustling cityscapes housing multinational corporations to the serene suburbs that families call home, we understood early on that each space has its own story and climate needs. This understanding has been the cornerstone of our approach, driving us to innovate and excel in a competitive market."
    },
    {
        img: installAC,
        title:"Our Philosophy",
        description:"At ADP Airtech, we believe that the right temperature can transform spaces into experiences.It's not just about cooling or heating; it's about creating environments that inspire productivity,comfort, and well-being. This belief is woven into the fabric of our operations, from meticulous HVAC design and installation to responsive customer service and maintenance. Our commitment to sustainability and efficiency is evident in every project we undertake, ensuring that we not only meet but exceed environmental standards."
    },
    {
        img: installAC,
        title:"Our Promise",
        description:"Our dedication to excellence is matched only by our commitment to your satisfaction. We understand that our success is directly linked to the trust our clients place in us, which is why we approach every project with integrity, transparency, and professionalism. Whether it's a custom-designed air conditioning system for a new industrial complex, a comprehensive maintenance contract for a residential building, or a swift repair job, ADP Airtech is your partner in climate control solutions."
    },
    {
        img: installAC,
        title:"Looking Forward",
        description:"As we look to the future, ADP Airtech is excited about the possibilities that lie ahead. The world of HVAC is constantly changing, with new technologies and sustainability practices shaping the way we think about air conditioning. We are committed to staying at the forefront of these developments, investing in research and training to bring you the latest in HVAC innovation."
    } 
]
