import React from 'react'
import Marquee from "react-fast-marquee";
import styles from './testimonials.module.scss'
import { IoIosStar } from "react-icons/io";
import { TESTIMONIALS } from '../../common/constants/general.constant';


const Testimonials = () => {
  // Variables
  const number = Array.from({length: 5}, (_,c) => c + 1)
  // Templates
  const renderReview = () => {
    return number?.map(() => {
      return <IoIosStar  style={{ fill: '#faaf00', fontSize: 25 }}  />
    })
  }


  return (
    <div >
      <div className={styles.mainHeading} >Testimonials</div>
      <Marquee>
        {TESTIMONIALS?.map((val) => {
            return <div className={styles.review} >
              <div className={styles.star} >
                {renderReview()}
                <p>{val.description}</p>
                <h4>{val.name}</h4>
            </div>
          </div>
        })}
        </Marquee>
    </div>
  )
}

export default Testimonials