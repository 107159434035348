import React, { useEffect } from 'react'
import styles from './about.module.css'
import { AboutUs } from '../../common/constants/general.constant'
import { Card } from 'antd';
import ceo from '../../Assests/Ankit.png'
import { useLocation } from 'react-router-dom';

const About = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  const renderLeftMenu = (data) => {
    return <div className={styles.leftMenu}  >
      <div className={styles.title} >{data?.title}</div>
      <div className={styles.description} >{data?.description}</div>
    </div>
  }

  const renderRightMenu = (img) => {
    return (
      <div className={styles.serviceimg} >
        <img src={img} alt='' />
      </div>
    )
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.aboutwrp}>
        <div className={styles.about}>
          <div className={styles.textabt}>About Us
          </div>
        </div>
      </div>
      <div className={styles.overlay} ></div>
      <div className={styles.secondWrapper}>
        <div className={styles.subwrp}>
          <div className={styles.mainHeading} >
            <h1>Empowering Your Environments, Enhancing Your Experience</h1>
          </div>
          <div className={styles.servicewrapper}>
            {
              AboutUs?.map((val, index) => {
                let isOdd = index % 2 === 0;
                return <div key={index} className={`${!isOdd ? styles.reverse : ""} ${styles.flex}`} >
                  <div data-aos={!isOdd ? "fade-left" : "fade-right"} >
                    {renderLeftMenu(val)}
                  </div>
                  <div style={{ marginLeft: isOdd ? 20 : 0, marginRight: !isOdd ? 20 : 0 }} data-aos={isOdd ? "fade-left" : "fade-right"} >
                    {renderRightMenu(val.img)}
                  </div>
                </div>
              })
            }

          </div>
          <Card className={styles.coreteam}
            title="Ankit Patel"
            bordered={false}
          >
            <div className={styles.ceodetail}>
              <div className={styles.aboutCeo}>
                <p>Ankit Patel is the visionary founder behind ADP Airtech, an innovative force in the air conditioning industry known for its commitment to quality, sustainability, and cutting-edge HVAC solutions. With a solid foundation in engineering and a passionate belief in the transformative power of technology, Ankit has propelled ADP Airtech to the forefront of the market. His leadership is characterized by a forward-thinking approach, emphasizing not only technical excellence but also a deep commitment to environmental stewardship and community engagement. Under his guidance, ADP Airtech stands as a beacon of innovation and integrity, promising not just superior climate control solutions but also a dedication to making a positive impact on the world.</p>
              </div>
              <div className={styles.lead}>
                <div className={styles.team}>
                  <img src={ceo} alt='' />
                </div>
              </div>
            </div>
          </Card>

        </div>

      </div>
    </div>
  )
}

export default About

