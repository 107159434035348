import React, { useEffect, useState } from 'react'
import styles from './contactUs.module.css'
import Inputfield from '../../Components/Inputfield/Inputfield'
import Img from '../../Assests/carousel-1.jpg'
import { Contacts } from '../../common/constants/general.constant'
import { FaInstagram } from "react-icons/fa6";
import { AiOutlineLinkedin } from "react-icons/ai";
import { TbBrandYoutube } from "react-icons/tb";
import { BsWhatsapp } from "react-icons/bs";
import { useLocation } from 'react-router-dom'


const ContactUs = () => {
  //variables
  const [input, setInput] = useState({
    name: "",
    email: "",
    subject: "",
    message: ""
  })
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  
  const handleSubmit = () => {
    setInput({
      email: "",
      name: "",
      message: "",
      subject: ""
    })
  }

  //Function
  const handleChange = (e) => {
    const { name, value } = e.target
    let _formData = { ...input }
    _formData[name] = value;
    setInput(_formData)
    console.log(input)
  }
  // placeholder === "subject" || "message"?
  return (
    <div className={styles.wrapper}>
      <div className={styles.contactwrapper}>
        <div className={styles.contactUs}>
          Contact Us
        </div>
      </div>
      <div className={styles.overlay}></div>
      <div className={styles.contact}>
        <div className={styles.left}>
          <img src={Img} alt='' />
        </div>
        <div className={styles.right}>
          <h2>If You Have Any Query, Please Contact Us</h2>
          <div className={styles.row} >
            <Inputfield placeholder="Your Name" onChange={handleChange} name="name" value={input.name} />
            <Inputfield className={styles.input} placeholder="Your Email" onChange={handleChange} name="email" value={input.email} />
          </div>
          <Inputfield className={styles.input} placeholder="Subject" onChange={handleChange} name="subject" value={input.subject} />
          <Inputfield placeholder="Message" onChange={handleChange} name="message" value={input.message} />
          <button className={styles.btn} onClick={handleSubmit}>Send Message</button>
        </div>
      </div>
      <div className={styles.contact}>
        <div className={styles.left}>
          <div className={styles.contactCardWrap}>
            <div className={styles.contactCard}>
              <div className={styles.companyname}>
                <h2>ADP Airtech</h2>
              </div>
              <div className={styles.contactdetails}>
                {
                  Contacts?.map((contactus, index) => {
                    return (
                      <div className={styles.contactusdetail} key={index}>
                        <div className={styles.icon}>
                          {contactus.icon}
                        </div>
                        <div className={styles.text}>
                          {contactus.text}
                        </div>
                      </div>
                    )
                  })
                }
              </div>
              <div className={styles.socialmedia}>
                <div className={styles.footer_social_media}>
                  <FaInstagram />
                  <AiOutlineLinkedin />
                  <TbBrandYoutube />
                  <BsWhatsapp />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.map}>
          <iframe
            className={styles.mapWrp}
            frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=adp airtech&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
        </div>
      </div>
    </div>
  )
}

export default ContactUs
