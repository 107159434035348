import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Ourservices from '../../Components/Ourservices/Ourservices'
const Services = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  return (
   <Ourservices/>
    
  )
}

export default Services
