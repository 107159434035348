import React from 'react'
import styles from './navbar.module.css'
import { Menus } from '../../common/constants/general.constant'
import { Link } from 'react-router-dom'
import {  useSetRecoilState } from 'recoil'
import { AtomIsSidebarOpen } from '../../global.store'
import { IoMdMenu } from "react-icons/io";
import logo from '../../Assests/ADP Logo TRANS.png'

const Navbar = () => {
    // Variables
    const setSidebarOpen = useSetRecoilState(AtomIsSidebarOpen);
    const width = window.innerWidth;
    const isMobile = width < 768 ? true : false;
    //  Function
    const handleCollaps = () => setSidebarOpen(true);

    return (
        <div className={styles.wrapper} >
            <div className={styles.leftMenu} >
                <div className={styles.logoWrapper} >
                    <Link className={styles.logo}to={'./'}>
                        <div className={styles.adpLogo}>
                          <img src={logo} alt=''/>
                        </div>
                        </Link>
                </div>
            </div>
            {isMobile && <button className={styles.burgerMenu} onClick={handleCollaps} ><IoMdMenu style={{fontSize:'1.2rem'}}  /></button>}
            {
                !isMobile &&
            <div className={styles.rightMenu} >
                {
                    Menus?.map((menu, index) => {
                        return <div className={styles.menu} key={index} >
                            <Link className={styles.menuItem} to={menu.redirect} >
                                {menu.label}
                            </Link>
                        </div>
                    })
                }
            </div>
                }
        </div>
    )
}

export default Navbar
