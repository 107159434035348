import React from 'react'
import styles from './Inputfield.module.css'

const Inputfield = ({ value, label, name, placeholder, type, onChange }) => {
   
    return (
        <div className={styles.form_field}>
            {label && <label htmlFor="input-field">{label}</label>}
            <input
                type={type}
                value={value}
                name={name}
                placeholder={placeholder}
                onChange={onChange}
            />
        </div>
    )
}

export default Inputfield
