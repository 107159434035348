import React from 'react'
// import { ServiceList } from '../../common/constants/general.constant'
import styles from './Ourservices.module.css'
import { ServiceList } from '../../common/constants/general.constant'

const Ourservices = () => {

    // Template 
    const renderLeftMenu = (data) => {
        return <div className={styles.leftMenu} >
            <div className={styles.title} >{data?.title}</div>
            <div className={styles.description} >{data?.description}</div>
        </div>
    }

    const renderRightMenu = (img) => {
        return (
            <div className={styles.serviceimg}>
                <img src={img} alt='' />
            </div>
        )
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.servicetwrp}>
                <div className={styles.service}>
                    <div className={styles.servicetext}>Services
                    </div>
                </div>
            </div>
            <div className={styles.overlay} ></div>
            <div className={styles.mainHeading} >
                <h1>We Provide professional Heating & Cooling Services</h1>
            </div>
            <div className={styles.servicewrapper}>
                {
                    ServiceList?.map((val, index) => {
                        let isOdd = index % 2 === 0;
                        return <div key={index} className={`${!isOdd ? styles.reverse : ""} ${styles.flex}`} >
                            {renderLeftMenu(val)}
                            {renderRightMenu(val.img)}
                        </div>
                    })
                }

            </div>
        </div>
    )
}

export default Ourservices
