import React,{useEffect} from 'react'
import styles from './products.module.css'
import { DomesticProducts, IndustrialProducts, } from '../../common/constants/general.constant'
import { useLocation } from 'react-router-dom';
const Product = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  

  return (
    <div className={styles.wrapper}>
      <div className={styles.aboutwrp}>
         <div className={styles.about}>
          <div className={styles.textabt}>Our Products
          </div>
        </div>
      </div>
      <div className={styles.overlay} ></div>

      <div className={styles.allproduct} >
        <div className={styles.heading} >Domestic Products</div>
        <div className={styles.productsWrapper}>

          {
            DomesticProducts?.map((products, index) => {
              return (
                <div className={styles.products} key={index}>
                  <div className={styles.productImage}>
                    <img src={products.img} alt='img' />
                  </div>
                  <div className={styles.acdetail}>
                    <div className={styles.productName} >
                      {products.title}
                    </div>
                  </div>

                </div>
              )
            })
          }
        </div>
        <div className={styles.heading} >Industrial Products</div>
        <div className={styles.productsWrapper}>

          {
            IndustrialProducts?.map((products, index) => {
              return (
                <div className={styles.products} key={index}>
                  <div className={styles.productImage}>
                    <img src={products.img} alt='img' />
                  </div>
                  <div className={styles.acdetail}>
                    <div className={styles.productName} >
                      {products.title}
                    </div>
                  </div>

                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

export default Product
