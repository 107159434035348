import React from "react";
import styles from "./footer.module.css";
import { FaInstagram } from "react-icons/fa6";
import { IoLogoYoutube } from "react-icons/io";
import { BsWhatsapp } from "react-icons/bs";
import { Link } from "react-router-dom";
import Logo from '../../Assests/ADP Logo TRANS.png'
import { FaLinkedin } from "react-icons/fa6";

const Footer = () => {

  // Template 
  const renderFooterTemp = (icon, color) =>{
    return <div className={`${styles[color]} ${styles.iconWrp}`} >{icon}</div>
  }

  return (
    <>
      <div className={styles.footerWrapper}>
        <div className={styles.footer_card}>
          <div className={styles.footer_1}>
            <div className={styles.companyLogo}>
              <img src={Logo} alt="" />
            </div>
            <div>
              <p>ADP AirTech</p>
              <img alt="" />
            </div>
            <p>
            Your All in One HVAC Solution
            </p>
          </div>
          <div className={styles.footer_2}>
            <Link className={styles.footer_2_link} to="/">Home</Link>
            <Link className={styles.footer_2_link} to="/about-us">About Us</Link>
            <Link className={styles.footer_2_link} to="/contact-us">Contact Us</Link>
            <Link className={styles.footer_2_link} to="/services"> Services</Link>
            <Link className={styles.footer_2_link} to="/product">Products</Link>
          </div>
          <div className={styles.footer_3}>
            <h2>Contact Us</h2>
            <div className={styles.footer_contact_info}>
              <p>
                Email : <span>adpairtech@gmail.com</span>
              </p>
              <p>
                Contact Number : <span>+91 9909025682</span>{" "}
              </p>
            </div>
            <div className={styles.footer_social_media}>
              {renderFooterTemp(<FaInstagram />, "insta")}
              {renderFooterTemp(<FaLinkedin />, "linkdin")}
              {renderFooterTemp(<IoLogoYoutube />, "youtube")}
              {renderFooterTemp(<BsWhatsapp />, "whatsapp")}
              
            </div>
          </div>
        </div>
        <div className={styles.copy_rights}>Copyright @ 2024 ADP AirTech</div>
      </div>
    </>
  );
};

export default Footer;
