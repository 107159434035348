import React, { useEffect } from 'react'
import styles from './home.module.css'
import { Companydetail, Countlist, LeadingBrands, Products, ServiceBrief, WorkCarosuel } from '../../common/constants/general.constant'
import Marquee from "react-fast-marquee"
import img1 from '../../Assests/icon-08-light.png'
import img2 from '../../Assests/icon-10-light.png'
import img3 from '../../Assests/icon-06-light.png'
import rightImg from '../../Assests/feature.jpg'
import Testimonials from '../../Components/Testimonials/Testimonials'
import { Link, useLocation } from 'react-router-dom'
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import { Carousel } from 'antd'


const Home = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
    const renderImages = () => {
        return <Marquee>
            {
                LeadingBrands?.map((url, index) => {
                    return <div key={index} className={styles.marqueLogo} >
                        <img
                            src={url}
                            className={styles.imageItem}
                            alt=''
                        />
                    </div>

                })
            }
        </Marquee>
    }

    const renderServiceCard = (img, heading, para) => {
        return <div className={styles.row} >
            <div className={styles.leftSec} >
                <div className={styles.iconImgWrp} >
                    <img src={img} alt='' />
                </div>
            </div>
            <div className={styles.rightSec} >
                <div className={styles.smHead} >{heading}</div>
                <div className={styles.para} >{para}</div>
            </div>
        </div>
    }

    const renderFont = {
        fontSize: "2rem"
    }
    return (
        <div className={styles.wrapper} >
            <div className={styles.firstWrapper} >
                <div className={styles.topSection} >
                    <div className={styles.banner}>
                        <h1>ADP Airtech</h1>
                        <h2>Your all in one HVAC Solution</h2>
                        <p>Welcome to ADP Airtech
                            At ADP Airtech, we are at the forefront of innovation in the air conditioning industry, dedicated to
                            elevating the standard of air comfort and quality in both domestic and industrial settings. As a
                            rapidly growing company, we pride ourselves on providing cutting-edge HVAC solutions tailored
                            to meet the unique needs of our clients. Our commitment to excellence, punctuality, and
                            competitive pricing ensures that we deliver unmatched value in every project we undertake</p>
                    </div>
                </div>
                <div className={styles.overlay} ></div>

            </div>
            <div className={styles.subWrapper} >
                <div className={styles.productWrapper} >
                    <div className={styles.mainHeading} data-aos="fade-right" >Our  Products</div>
                    <div className={styles.productdetail} data-aos="fade-right" >
                        Explore our wide selection of air conditioning units,
                        ranging from compact residential models to powerful industrial solutions. We feature the latest in
                        HVAC technology from trusted brands, ensuring reliability, energy efficiency, and superior air
                        quality.
                    </div>
                    <div className={styles.domesticProductsWrp} data-aos="fade-left">
                        <div className={styles.productsWrapper}>

                            {
                                Products?.map((products, index) => {
                                    return (
                                        <div className={styles.products} key={index}>
                                            <div className={styles.productImage}>
                                                <img src={products.img} alt='img' />
                                            </div>
                                            <div className={styles.acdetail}>
                                                <div className={styles.productName} >
                                                    {products.title}
                                                </div>
                                            </div>

                                        </div>
                                    )
                                })
                            }
                        </div>
                        <Link to={"./product"}>
                            <button className={styles.viewproductbtn}>View All </button>
                        </Link>
                    </div>
                </div>
                <div className={styles.leadingBrandsWrp} >
                    <div className={styles.mainHeading} >All Leading Brands</div>
                    {renderImages()}
                </div>
                <div className={styles.service}>
                    <div className={styles.mainHeading} >Services</div>
                    <div className={styles.subwrapper}>
                        <div className={styles.left} data-aos="fade-right">
                            <div className={styles.serviceImg}>
                                <img src={rightImg} alt='' />
                            </div>
                        </div>
                        <div className={styles.right}>
                            {
                                ServiceBrief?.map((service, index) => {
                                    return (
                                        <div key={index} className={styles.card} data-aos="fade-left">
                                            <h2>{service.title}</h2>
                                            <p>{service.description}</p>
                                        </div>
                                    )
                                })
                            }
                            <Link to={'./services'} >
                                <button className={styles.abtus}> Services</button>
                            </Link>

                        </div>
                    </div>
                </div>
                <div className={styles.companySucess}>
                    <div className={styles.companyfirstWrapper} >
                        <div className={styles.companytopSection} >
                            <div className={styles.banner}>
                                <div className={styles.companyDetail}>
                                    {
                                        Countlist?.map((count) => {
                                            return (
                                                <div className={styles.count}>
                                                    <CountUp
                                                        style={{ fontWeight: "3rem" ,fontSize:"3.5rem"}}
                                                        start={0}
                                                        end={count.count}
                                                        duration={5}
                                                        separator=" "
                                                        decimals={0}
                                                        decimal=","
                                                        redraw={true}

                                                    >
                                                        {({ countUpRef, start }) => (
                                                            <div>
                                                                <VisibilitySensor onChange={start} delayedCall>
                                                                    <div>
                                                                        <span ref={countUpRef} /> +
                                                                        <div className={styles.countlabel}>
                                                                            {count.label}
                                                                        </div>
                                                                    </div>
                                                                </VisibilitySensor>
                                                            </div>
                                                        )}
                                                    </CountUp>

                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={styles.overlay} ></div>

                    </div>
                </div>
                <div className={styles.whyCus} >

                    <div className={styles.mainHeading} >We Provide professional Heating & Cooling Services</div>
                    <div className={styles.whyChooseUsWrp} >
                        <div className={styles.leftMenu} data-aos="fade-right">
                            <div className={styles.head} >Few Reasons Why People Choosing Us!</div>
                            <p>Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit. Aliqu diam amet diam et eos. Clita erat ipsum et lorem et sit, sed stet lorem sit clita duo justo magna dolore erat amet</p>
                            <div>

                                {renderServiceCard(img1, "Trusted Service Center", "Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit. Aliqu diam amet diam et eos.")}
                                {renderServiceCard(img2, "Reasonable Price", "Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit. Aliqu diam amet diam et eos.")}
                                {renderServiceCard(img3, "24/7 Supports", "Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit. Aliqu diam amet diam et eos.")}
                            </div>
                            <Link to={'./about-us'}>
                                <button className={styles.abtus}> About Us</button>
                            </Link>
                        </div>
                        <div className={styles.rightMenu} data-aos="fade-left" >
                            <div className={styles.imgWrp} >
                                <img src={rightImg} alt='' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.wrokWrapper} >
                    <div className={styles.mainHeading} >Projects</div>
                    {/* <Carousel dotPosition="left" autoplay > */}
                    <Marquee>
                        {
                            WorkCarosuel?.map((val, index) => {
                                return (
                                    <div className={styles.work}>
                                        <div key={index} className={styles.workImgWrp} >
                                            <img src={val.work} alt='' />
                                        </div>
                                        <div className={styles.description} >
                                            <h2>{val.place}</h2>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        </Marquee>
                    {/* </Carousel> */}
                </div>
                <div className={styles.testimonilesWrp} >
                    <Testimonials />
                </div>
            </div>

        </div>
    )
}

export default Home
