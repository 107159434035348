import React from 'react'
import styles from './sidebar.module.css'
import { Menus } from '../../common/constants/general.constant'
import { IoMdClose } from "react-icons/io";
import { useSetRecoilState } from 'recoil';
import { AtomIsSidebarOpen } from '../../global.store';
import { Link } from 'react-router-dom';

const Sidebar = () => {
    // Varaibales
    const setSidebarOpen = useSetRecoilState(AtomIsSidebarOpen);

    // Functions
    const handleClose = () => setSidebarOpen(false);

    return (
        <div className={styles.wrapper} >
            <div className={styles.header} >
                <div className={styles.logo} >LOGO</div>
                <button onClick={handleClose} className={styles.closeBtn} >
                    <IoMdClose />
                </button>
            </div>
            <div className={styles.contentWrapper} >
                {
                    Menus?.map((val, index) => {
                        return <div key={index} className={styles.menu} onClick={handleClose} >
                            <Link className={styles.label} to={val.redirect} >
                                {val.label}
                            </Link>
                        </div>
                    })
                }
            </div>
        </div>
    )
}

export default Sidebar
