import React from 'react'
import Navbar from '../Components/Navbar/Navbar'
import Footer from '../Components/Footer/Footer'
import { useRecoilValue } from 'recoil'
import { AtomIsSidebarOpen } from '../global.store'
import Sidebar from '../Components/Sidebar/Sidebar'

const Layouts = ({ children }) => {
  // Variables
  const collapse = useRecoilValue(AtomIsSidebarOpen);
  return (
    <div style={{ position: "relative"}} >
      <Navbar />
      {collapse && <Sidebar />}
      {
        !collapse &&
        <>
          <div>
            {children}
          </div>
          <Footer />
        </>
      }
    </div>

  )
}

export default Layouts
