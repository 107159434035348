import React, { useEffect } from 'react'
import { BrowserRouter, Route, Routes,useLocation } from 'react-router-dom'
import Home from './pages/Home/Home'
import Layouts from './Layouts/Layouts'
import About from './pages/About/About'
import ContactUs from './pages/Contact Us/ContactUs'
import Services from './pages/Services/Services'
import Product from './pages/Products/Product'
import { RecoilRoot } from 'recoil'
import AOS from 'aos';
import 'aos/dist/aos.css';



const App = () => {

  useEffect(() => {
    AOS.init({
      duration: 1500,
      easing: "ease-out-cubic",
    });
  }, [])

  

  return (
    <div>
      <BrowserRouter>
        <RecoilRoot>
          <Layouts>
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/about-us' exact element={<About />} />
              <Route path='/contact-us' exact element={<ContactUs />} />
              <Route path='/services' exact element={<Services />} />
              <Route path='/product' exact element={<Product />} />
            </Routes>
          </Layouts>
        </RecoilRoot>

      </BrowserRouter>
    </div>
  )
}

export default App
